import { SparepartSearchOptionValue } from '@emico-hooks/graphql-schema-types'
import { stripMaybes } from '@emico-utils/graphql-data-utils'
import styled from '@emotion/styled'
import { t, Trans } from '@lingui/macro'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useForm, useFieldArray } from 'react-hook-form'

import { minWidth } from '@emico/styles'

import { useSparepartSearchOptions } from '../lib/useGetSparepartSearchOptions'
import theme from '../theme'
import ButtonPrimary from './ButtonPrimary'
import { ErrorBlock } from './ErrorBlock'
import Select from './Select'

enum SelectFieldUpdateStatusEnum {
  INITIAL = 'initial',
  NONE = 'none',
  APPEND = 'append',
}

interface SparepartAttribute {
  attributeCode: string
  attributeLabel: string
  options: SparepartSearchOptionValue[]
  selectedOption?: string
}

interface FormValues {
  attributes: SparepartAttribute[]
}

const StyledSelect = styled(Select as any)`
  margin: ${theme.spacing.md} 0;
`

const StyledButtonPrimary = styled(ButtonPrimary)`
  margin-top: ${theme.spacing.lg};

  @media ${minWidth('md')} {
    min-width: 186px;
  }
`

function getSelectedAttributes(attributes: SparepartAttribute[]) {
  return attributes.filter((attribute) => Boolean(attribute.selectedOption))
}

function convertFormFieldAttributes(attributes: SparepartAttribute[]) {
  return attributes.map((attribute) => ({
    attribute_code: attribute.attributeCode,
    selected_option: attribute.selectedOption,
  }))
}

interface Props {
  handleFormSubmit: (attributes: SparepartAttribute[]) => void
}

const SparepartsSearchForm = ({ handleFormSubmit }: Props) => {
  const [selectFieldUpdateStatus, setSelectFieldUpdateStatus] =
    useState<SelectFieldUpdateStatusEnum>(SelectFieldUpdateStatusEnum.INITIAL)

  const { getOptions, data, isLoading } = useSparepartSearchOptions()

  const { handleSubmit, register, control, getValues, setValue } =
    useForm<FormValues>()

  /**
   * useFieldArray hook for dynamic form field rendering
   *
   * fields: array of field data for rendering select fields
   * append: function to add new field at the end of the fields array
   * remove: function to remove existing field from the fields array
   */
  const { fields, append, remove } = useFieldArray({
    name: 'attributes',
    control,
  })

  const currentSelection = data?.currentSelection ?? []
  const availableProducts = data?.products ?? []
  const availableSelectField = data?.optionsAvailableForSelection ?? undefined

  const hasNewSelectField = Boolean(availableSelectField?.attributeCode)
  const availableProductCount = availableProducts.length

  function handleSelectOption(
    event: ChangeEvent<HTMLInputElement>,
    index: number,
  ) {
    const value = event.target.value
    const fieldsCount = fields.length
    const isPreviousField = index + 1 < fieldsCount

    setValue(`attributes.${index}.selectedOption`, value)
    setSelectFieldUpdateStatus(SelectFieldUpdateStatusEnum.APPEND)

    /**
     * If a previous field is changed, all later added fields should be removed,
     * due to different form-based response.
     */
    if (isPreviousField) {
      for (let i = fieldsCount; i > index; i -= 1) {
        remove(i)
      }
    }

    /**
     * Get new spare part search options, based on existing and latest selected field values.
     */
    const { attributes } = getValues()
    const selectedAttributes = getSelectedAttributes(attributes)
    const selectedFormOptions = convertFormFieldAttributes(selectedAttributes)

    getOptions(selectedFormOptions)
  }

  async function onSubmit(formValues: FormValues) {
    if (availableProducts) {
      handleFormSubmit(getSelectedAttributes(formValues.attributes))
    }
  }

  /**
   *  Retrieve initial spare part search available select field
   */
  useEffect(() => {
    getOptions()
  }, [getOptions])

  /**
   * Dynamically create form field based on availableSelectField values.
   * The SparepartAttribute values are appended to useFieldArray's fields.
   */
  useEffect(() => {
    const canAppend =
      selectFieldUpdateStatus === SelectFieldUpdateStatusEnum.INITIAL ||
      (selectFieldUpdateStatus === SelectFieldUpdateStatusEnum.APPEND &&
        availableProductCount > 0)

    if (hasNewSelectField && availableSelectField && canAppend) {
      append({
        attributeCode: availableSelectField.attributeCode ?? '',
        attributeLabel: availableSelectField.attributeLabel ?? '',
        options: availableSelectField.options?.filter(stripMaybes) ?? [],
      })

      if (
        selectFieldUpdateStatus === SelectFieldUpdateStatusEnum.INITIAL &&
        currentSelection.length !== 0
      ) {
        setSelectFieldUpdateStatus(SelectFieldUpdateStatusEnum.NONE)
      }
    }
  }, [
    append,
    availableProductCount,
    availableSelectField,
    currentSelection.length,
    hasNewSelectField,
    selectFieldUpdateStatus,
  ])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {fields.map((field, index) => {
        const defaultValue = field.options?.find(
          (option) => option?.selected,
        )?.value

        return (
          <StyledSelect
            key={field.id}
            {...register(`attributes.${index}.selectedOption`)}
            control={control}
            defaultValue={defaultValue}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              handleSelectOption(event, index)
            }}
          >
            <option value="" selected disabled>
              {field.attributeLabel}
            </option>

            {field.options.map((option) =>
              option.value ? (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ) : null,
            )}
          </StyledSelect>
        )
      })}

      <StyledButtonPrimary
        isLoading={isLoading}
        analyticsContext="spareparts"
        analyticsName="search.by.description"
        colorType="neutral"
        disabled={availableProducts?.length === 0}
      >
        <Trans>Search</Trans>
      </StyledButtonPrimary>

      {!isLoading &&
        selectFieldUpdateStatus !== SelectFieldUpdateStatusEnum.INITIAL &&
        !availableProductCount && (
          <ErrorBlock
            title={t({
              message: 'Sorry, we could not find products',
            })}
          />
        )}
    </form>
  )
}

export default SparepartsSearchForm
