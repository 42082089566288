import { useApolloClient, gql, TypedDocumentNode } from '@apollo/client'
import productCardFragment from '@emico-hooks/product-card-fragment'
import { useCallback, useState } from 'react'

import { SparepartSearchOptionInput } from '@emico/graphql-schema-types'

import {
  GetSparepartSearchOptionsQuery,
  GetSparepartSearchOptionsQueryVariables,
} from './useGetSparepartSearchOptions.generated'

const SparepartSearchOption = gql`
  fragment SparepartSearchOption on SparepartSearchOption {
    attributeCode
    attributeLabel

    options {
      label
      value
      selected
    }
  }
`

export const getSparepartSearchOptionsQuery = gql`
  query GetSparepartSearchOptions(
    $selectedOptions: [SparepartSearchOptionInput]
    $pageSize: Int
    $currentPage: Int
  ) {
    getSparepartSearchOptions(
      selectedOptions: $selectedOptions
      pageSize: $pageSize
      currentPage: $currentPage
    ) {
      currentSelection {
        ...SparepartSearchOption
      }

      optionsAvailableForSelection {
        ...SparepartSearchOption
      }
      products {
        ...ProductCardFragment
      }
    }
  }

  ${SparepartSearchOption}
  ${productCardFragment}
` as TypedDocumentNode<
  GetSparepartSearchOptionsQuery,
  GetSparepartSearchOptionsQueryVariables
>

/**
 * Query to fetch sparepart search options.
 *
 *  @returns Object with available selection options, array of currently selected options
 *  and array of available ProductFragments
 *
 * Usage example:
 *
 * ```ts
 * export function exampleFunction() {
 *    const { getOptions, data, isLoading } = useGetSparepartSearchOptions()
 * }
 * ```
 */

export const useSparepartSearchOptions = () => {
  const client = useApolloClient()
  const [data, setData] = useState<GetSparepartSearchOptionsQuery>()
  const [hasError, setHasError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const getOptions = useCallback(
    /**
     * @param selectedOptions array of SparepartSearchOptionInput
     * @param currentPage for paging purposes, defaults to 1
     * @param pageSize for paging purposes, defaults to 10
     */
    async (
      selectedOptions: SparepartSearchOptionInput[] = [],
      currentPage: number = 1,
      pageSize: number = 10,
    ) => {
      setIsLoading(true)
      setHasError(false)
      setData(undefined)

      try {
        const { data: sparepartOptions } = await client.query({
          query: getSparepartSearchOptionsQuery,
          variables: {
            currentPage: Number(currentPage),
            pageSize: Number(pageSize),
            selectedOptions,
          },
        })

        setData(sparepartOptions)
      } catch (error) {
        setHasError(true)
      } finally {
        setIsLoading(false)
      }
    },
    [client],
  )

  return {
    isLoading,
    hasError,
    data: data?.getSparepartSearchOptions,
    getOptions,
  }
}
